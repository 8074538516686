@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700;900&display=swap');

@font-face {
  font-family: 'BebasNeue';
  src: url('https://1770075938.rsc.cdn77.org/onthejlo/cant-get-enough-landing/fonts/BebasNeue-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PlantinStd-Light';
  src: url('https://1770075938.rsc.cdn77.org/onthejlo/cant-get-enough-landing/fonts/PlantinStd-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
