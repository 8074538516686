@import '~bootstrap/scss/bootstrap';

#root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  @media screen and (min-width: 992px) {
    margin-top: 0;
  }
}

body {
  background-color: #212f26;
  background: linear-gradient(0deg, hsla(141, 23%, 22%, 1) 0%, hsla(141, 18%, 16%, 1) 100%);
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hr {
  background-color: #26453b;
}

img {
  width: 100%;
  height: auto;
}

.body-content {
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.jlo-logo-wrapper {
  background-color: #000;
  overflow: hidden;
  padding: 4px 10px 20px 10px;

  @media screen and (min-width: 1000px) {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 16px 20px 16px;
    border-bottom-left-radius: 20px;
  }

  &.video-container {
    border-radius: 0;
  }
}

.jlo-logo-container {
  width: 100%;
  margin: auto;
  background-color: #000;
  max-width: 200px;
}

.logo-container {
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 20px 40px;
  // border-bottom: 1px solid #2b3d33;

  .logo-1 {
    max-width: 280px;

    @media screen and (min-width: 576px) {
      max-width: 350px;
    }

    @media screen and (min-width: 992px) {
      max-width: 500px;
    }
  }

  .logo-2 {
    max-width: 32px;

    @media screen and (min-width: 576px) {
      max-width: 40px;
    }

    @media screen and (min-width: 992px) {
      max-width: 60px;
    }
  }

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.heading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-family: 'BebasNeue';
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
}

.album-cover {
  max-width: 760px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.4);
}

.heading {
  font-family: 'BebasNeue';
  font-weight: normal;
  font-size: 44px;
  letter-spacing: 0.02rem;

  @media screen and (min-width: 768px) {
    font-size: 40px;
  }

  @media screen and (min-width: 992px) {
    font-size: 54px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 62px;
  }
}

.subheading {
  font-family: 'BebasNeue';
  font-weight: normal;
  font-size: 32px;
  letter-spacing: 0.02rem;

  @media screen and (min-width: 768px) {
    font-size: 36px;
  }

  @media screen and (min-width: 992px) {
    font-size: 48px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 52px;
  }
}

.form-wrapper {
  max-width: 560px;
  margin: auto;
  margin-bottom: 20px;

  .row > * {
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (min-width: 992px) {
    margin-bottom: 40px;
  }
}

// previous styles

input {
  font-family: 'BebasNeue';
  font-weight: normal;
  border: 1px solid rgba(#b3b3b3, 1);
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0.5rem;
  width: 100%;
  color: #1e1e1e;
  background-color: #fff;
  min-height: 56px;
  padding: 0 15px;
  font-size: 22px;

  @media screen and (min-width: 768px) {
    min-height: 64px;
  }

  &:focus-visible {
    outline: none;
  }

  &.error {
    border-color: red;
  }
}

input::placeholder {
  color: #777;
  letter-spacing: 0.025rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  border-color: #1e1e1e;
  -webkit-box-shadow: 0 0 0 9999px white inset !important;
  -webkit-text-fill-color: #1e1e1e !important;
}

.header-text {
  font-family: 'BebasNeue';
  font-weight: normal;
  text-align: center;
  font-size: 26px;
  line-height: 1.2;

  @media screen and (min-width: 768px) {
    font-size: 36px;
  }

  @media screen and (min-width: 992px) {
    font-size: 42px;
  }
}

.subheader-text {
  font-family: 'PlantinStd-Light';
  font-weight: normal;
  text-align: center;
  font-size: 20px;
  line-height: 1.4;

  @media screen and (min-width: 768px) {
    font-size: 22px;
  }
}

.bird-icon {
  max-width: 60px;
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    max-width: 80px;
  }
}

.disclaimer {
  color: #fff;
  padding: 0 0 40px;
  font-size: 10px;
  margin-top: 20px;
  margin-bottom: 0;
  text-align: center;

  a {
    display: inline-block;
    color: #fff;
    margin-bottom: 4px;
    transition: color 250ms ease-in-out;

    &:hover {
      color: #7affcb;
    }
  }

  @media screen and (min-width: 768px) {
    font-size: 12px;
  }
}

.main-content {
  max-width: 560px;
  margin: 0 auto;
  padding: 0;

  button,
  .button-link,
  .chimney-embed .chimney-btn,
  .chimney-embed .chimney-entry-button .chimney-btn {
    font-family: 'BebasNeue';
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #000;
    border: 1px solid #000;
    border-radius: 0.5rem;
    padding: 8px 32px;
    box-shadow: none;
    border: none;
    font-size: 22px;
    min-height: 56px;
    transition: all 0.3s ease;
    width: 100%;
    text-decoration: none;
    line-height: 1;
    letter-spacing: 0.026rem;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);

    @media screen and (min-width: 576px) {
      display: inline-flex;
      width: auto;
    }

    @media screen and (min-width: 768px) {
      margin-top: 0;
      min-height: 64px;
      font-size: 27px;
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }

    &:hover,
    &:active,
    &:focus-visible {
      background-color: #7affcb;
      color: #000;
      border: 1px solid #7affcb;
      transition: all 0.3s ease;
    }

    &.outline-primary {
      background-color: transparent;
      color: #fff;
      border: 1px solid #fff;

      &:hover {
        background-color: #000;
        color: #fff;
        border: 1px solid #000;
      }
    }
  }
}

.success-copy {
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 24px;

  @media screen and (min-width: 768px) {
    max-width: 80%;
  }
}

.chimney-embed {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;

  .chimney-body {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .chimney-legal-notes {
    display: none;
  }

  .chimney-entry-button {
    padding: 0 !important;
  }
}

.cookie-container {
  color: white;
  background-color: rgb(10, 112, 39);
  padding: 1rem;
  position: fixed;
  margin-bottom: 0;
  width: 100%;
  left: 0;
  z-index: 5;

  @media screen and (min-width: 576px) {
    left: auto;
    border-radius: 6px;
    right: 1rem;
    margin-bottom: 1rem;
    max-width: 20rem;
  }

  button {
    color: #fff;
    border-radius: 0.5rem;
    padding: 8px 32px;
    box-shadow: none;
    border: none;
    transition: all 0.3s ease;
    width: 100%;
    background-color: white;
    color: black;
    font-size: 14px;
    padding: 1rem;
    min-height: 36px;
    margin-top: 0;
  }

  .cookie-header {
    color: white;
    font-size: 18px;
  }

  .cookie-text {
    color: white;
    font-size: 14px;
    text-align: left;
  }
}

.error-copy {
  color: red;
  position: absolute;
  font-size: 12px;
  bottom: -22px;
  animation: fadeInDown 0.2s linear forwards;
}

.privacy-content {
  color: #000;
  background-color: #fff;
  padding: 1.5rem 1.5rem 3rem;
  margin-top: 0;

  &.container {
    margin-left: 0;
    margin-right: 0;

    @media screen and (min-width: 556px) {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    @media screen and (min-width: 992px) {
      margin-top: 9rem;
    }

    @media screen and (min-width: 1550px) {
      max-width: 1190px;
    }

    @media screen and (min-width: 992px) {
      max-width: 900px;
    }
  }

  h2 {
    font-size: 1.6rem;
    margin-top: 4rem;
  }

  h3 {
    font-size: 1rem;
    margin-top: 1rem;
    line-height: 1.3;
  }

  p {
    color: #000;
    text-align: left;
    font-size: 1rem;
    overflow-wrap: break-word;
  }

  ul {
    padding-left: 1.3rem;
  }

  ol {
    padding-left: 1.6rem;
  }

  li {
    padding-bottom: 10px;
  }

  .toc-content {
    li::marker {
      font-size: 1.6rem;
    }

    .list-revert {
      li::marker {
        font-size: 0.9rem;
      }
    }
  }

  a {
    color: #00933b;
  }

  tr,
  td,
  th {
    border-width: 1px;
    padding: 0.25rem;
    font-size: 12px;

    @media screen and (min-width: 576px) {
      padding: 0.5rem;
      font-size: 16px;
    }
  }
}

.logo-wrapper {
  @media screen and (max-width: 991.98px) {
    background-color: #000;
  }

  .logo {
    @media screen and (max-width: 991.98px) {
      position: static;
      margin: auto;
      background-color: #000000;
    }
  }
}

.language-select-group {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  caret-color: transparent;

  @media screen and (min-width: 768px) {
    margin-bottom: 3rem;
  }

  p {
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-size: 0.775rem;
    margin-bottom: 1rem;

    @media screen and (min-width: 768px) {
      margin-top: 0;
    }
  }

  [class$='-singleValue'] {
    text-align: center;
    color: #00933c;
    font-size: 16px;
    font-weight: 500;
    margin-top: -0.3rem;
  }

  [class$='-Input'] {
    max-height: 52px;
    margin: 0;
    padding: 0;
  }

  [class$='-control'] {
    color: #00933c;
    background-color: #fff;
    border-radius: 0.5rem;
    border: 1px solid;
    border-color: #00933c !important;
    box-shadow: none;
    cursor: pointer;
    outline: none;
    max-height: 56px;

    [class$='-Input'] {
      &:before {
        transform: rotate(0);
        transition: all 0.15s linear;
      }
    }
  }

  [class$='-menu'] {
    background-color: #fff;
    border: 1px solid #b3b3b3;
    border-radius: 0;
    color: #00933c;

    > * {
      padding: 0;
      text-align: center;
      cursor: pointer;
      min-height: 52px;

      &:hover,
      &:active,
      &:focus-within {
        background-color: red;
      }
    }
  }

  .menu-closed,
  .menu-open {
    &:before {
      content: '';
      background-image: url(../images/icon-arrow-green.svg);
      background-size: 15px 15px;
      background-repeat: no-repeat;
      width: 15px;
      height: 15px;
      position: absolute;
      right: calc(50% - 50px);
      top: calc(50% - 7px);
      z-index: 2;
      pointer-events: none;
    }
  }

  .menu-closed {
    &:before {
      transform: rotate(0);
      transition: all 0.15s linear;
    }
  }

  .menu-open {
    &:before {
      transform: rotate(180deg);
      transition: all 0.15s linear;
    }
  }

  [class$='-ValueContainer'] {
    padding: 0;
  }

  input {
    max-height: 56px;
    caret-color: rgba(0, 0, 0, 0);
  }

  [class$='-MenuList'] {
    [class$='-option'] {
      background-color: #fff;
      color: #000;

      &:hover {
        background-color: #00933b;
        color: #fff;
      }
    }

    > * {
      min-height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:active {
        background: rgb(10, 112, 39);
        color: #000;
        cursor: pointer;
      }

      &:last-child {
        border-top: 1px solid #fff;
      }
    }
  }
}

.video-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 1rem;
  background: #000;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  @media screen and (min-width: 576px) {
    padding: 0 4rem;
  }

  @media screen and (min-width: 1200px) {
    padding: 0 12rem;
  }

  .video-js {
    border: 4px solid white;
    top: 50%;
    transform: translateY(-50%);
    max-width: 2070px !important;
    margin: 0 auto;
  }

  .video-js.vjs-fullscreen {
    border: none;
  }

  .vjs-big-play-button {
    background-color: #00933c !important;
    background-image: url('../images/icon-arrow-play.svg');
    background-position: 50% 50%;
    background-size: 40px;
    background-repeat: no-repeat;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent;
    width: 100%;
    height: 100%;

    @media screen and (min-width: 576px) {
      background-size: 65px;
    }

    @media screen and (min-width: 992px) {
      background-size: 86px;
    }

    &:before {
      content: '';
      display: none;
    }

    &:hover {
      background-color: transparent;
      opacity: 0.7;
    }
  }

  .video-js .vjs-big-play-button {
    height: 2em;
    width: 2em;
    margin-top: -1em;
    margin-left: -1em;
    border-radius: 50%;

    @media screen and (min-width: 576px) {
      height: 4em;
      width: 4em;
      margin-top: -2em;
      margin-left: -2em;
    }

    @media screen and (min-width: 992px) {
      height: 5em;
      width: 5em;
      margin-top: -2.5em;
      margin-left: -2.5em;
    }
  }

  .video-js .vjs-control-bar,
  .video-js .vjs-big-play-button,
  .video-js .vjs-modal-dialog {
    background-color: #00933c;
  }

  button {
    border: none !important;
  }

  .vjs-big-play-button .vjs-icon-placeholder:before {
    display: none;
  }
}

.logo {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  max-width: 160px;
  padding: 0.7rem;

  @media screen and (min-width: 992px) {
    border-bottom-right-radius: 20px;
    background-color: #000;
    max-width: 190px;
  }
}

.error-state-subscribe {
  margin-top: 2rem;

  p {
    margin-bottom: 0;
  }

  .error-header {
    color: red;
    margin-bottom: 0.5rem;
  }

  .error-subheader {
    margin-bottom: 0.55rem;
  }
}

.svg-loader {
  display: flex;
  position: relative;
  align-content: space-around;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.bold-header {
  font-family: 'BebasNeue';
  font-weight: normal;
  font-size: 1.75rem;
  margin-top: 1.5rem;
  text-align: center;
}

.seperator {
  height: 5px !important;
  width: 60px;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 15px;
  background-color: #7affcb;
  opacity: 1;
}

.loader-svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  fill: none;
  stroke-width: 5px;
  stroke-linecap: round;
  stroke: #00933b;
}

.loader-svg.bg {
  stroke-width: 8px;
  stroke: rgba(161, 161, 161, 0.25);
}

.animate {
  stroke-dasharray: 242.6;
  animation: fill-animation 1s cubic-bezier(1, 1, 1, 1) 0s infinite;
}

@keyframes fill-animation {
  0% {
    stroke-dasharray: 40 242.6;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 141.3;
    stroke-dashoffset: 141.3;
  }

  100% {
    stroke-dasharray: 40 242.6;
    stroke-dashoffset: 282.6;
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  order: 2;

  @media screen and (min-width: 992px) {
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    top: 0;
  }
}

.email-input {
  position: relative;
  margin-bottom: 1.5rem;

  button {
    width: 100% !important;
  }
}

#SignUpForm button, #UnsubscribeForm button {
  width: 100%;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRight {
}

.fade-in {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animate-w-100 {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

.arrow-animate {
  svg {
    position: relative;
    top: 10px;
    width: 100%;
    max-width: 50px;
    height: auto;
  }
}

.logo-letter-container {
  display: flex;

  img {
    max-width: 23px;
  }
}

.logo-copy {
  max-width: 65px;
  animation: fadeInDown 0.2s linear forwards;
  transform: scale(0);
  margin-bottom: 6px;
}

.logo-letter {
  width: 23px;
  height: 23px;
  animation: grow 0.25s linear forwards;
  transform: scale(0);

  &:nth-child(n + 2) {
    margin-left: 0.2rem;
  }

  &:nth-child(1) {
    animation-delay: 0.25s;
  }

  &:nth-child(2) {
    animation-delay: 0.5s;
  }

  &:nth-child(3) {
    animation-delay: 0.75s;
  }
}

.arrow-fill {
  opacity: 0;
  animation: fill 0.5s linear forwards;
  animation-delay: 1.85s;
}

.arrow-line {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 0.75s linear alternate;
  animation-delay: 1.1s;
}

@keyframes dash {
  from {
    stroke-dashoffset: 822;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes grow {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.sign-in {
  @media screen and (min-width: 1000px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .form-container {
    padding-top: 0rem;
  }
}

.vjs-big-play-button {
  display: none !important;
}

.cookie-container {
  color: white;
  background-color: rgb(10, 112, 39);
  padding: 1rem;
  position: fixed;
  margin-bottom: 0;
  width: 100%;
  left: 0;
  z-index: 5;
  @media screen and (min-width: 576px) {
    left: auto;
    border-radius: 6px;
    right: 1rem;
    margin-bottom: 1rem;
    max-width: 20rem;
  }

  button {
    color: #fff;
    font-weight: 700;
    border-radius: 0.5rem;
    padding: 8px 32px;
    box-shadow: none;
    border: none;
    transition: all 0.3s ease;
    width: 100%;
    background-color: white;
    color: black;
    font-size: 14px;
    padding: 1rem;
    min-height: 36px;
    margin-top: 0;
  }
  .cookie-header {
    color: white;
    font-weight: 600;
    font-size: 18px;
  }
  .cookie-text {
    color: white;
    font-size: 14px;
    text-align: left;
  }
}
.error-copy {
  color: red;
  position: absolute;
  font-size: 12px;
  bottom: -22px;
  animation: fadeInDown 0.2s linear forwards;
}

.privacy-content {
  color: #000;
  padding: 1.5rem 1.5rem 3rem;
  margin-top: 0;

  .logo {
    @media screen and (max-width: 991.98px) {
      position: static;
      margin: auto;
      background-color: #000000;
      margin-bottom: 1rem;
    }
  }

  &.container {
    margin-left: 0;
    margin-right: 0;
    @media screen and (min-width: 556px) {
      margin-left: 1rem;
      margin-right: 1rem;
    }
    @media screen and (min-width: 992px) {
      margin-top: 9rem;
    }
    @media screen and (min-width: 1550px) {
      max-width: 1190px;
    }
    @media screen and (min-width: 992px) {
      max-width: 900px;
    }
  }
  h2 {
    font-size: 1.6rem;
    margin-top: 4rem;
    font-weight: 700;
  }
  h3 {
    font-size: 1rem;
    margin-top: 1rem;
    font-weight: 600;
    line-height: 1.3;
  }
  p {
    color: #000;
    text-align: left;
    font-size: 1rem;
    overflow-wrap: break-word;
  }
  ul {
    padding-left: 1.3rem;
  }
  ol {
    padding-left: 1.6rem;
  }
  li {
    padding-bottom: 10px;
  }
  .toc-content {
    li::marker {
      font-weight: 700;
      font-size: 1.6rem;
    }
    .list-revert {
      li::marker {
        font-size: 0.9rem;
      }
    }
  }
  a {
    color: #00933b;
  }
  tr,
  td,
  th {
    border-width: 1px;
    padding: 0.25rem;
    font-size: 12px;
    @media screen and (min-width: 576px) {
      padding: 0.5rem;
      font-size: 16px;
    }
  }
}

.language-select-group {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  caret-color: transparent;
  @media screen and (min-width: 768px) {
    margin-bottom: 3rem;
  }
  p {
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-size: 0.775rem;
    margin-bottom: 1rem;
    @media screen and (min-width: 768px) {
      margin-top: 0;
    }
  }
  [class$='-singleValue'] {
    text-align: center;
    color: #00933c;
    font-size: 16px;
    font-weight: 500;
    margin-top: -0.3rem;
  }
  [class$='-Input'] {
    max-height: 52px;
    margin: 0;
    padding: 0;
  }
  [class$='-control'] {
    color: #00933c;
    background-color: #fff;
    border-radius: 0.5rem;
    border: 1px solid;
    border-color: #00933c !important;
    box-shadow: none;
    cursor: pointer;
    outline: none;
    max-height: 56px;
    [class$='-Input'] {
      &:before {
        transform: rotate(0);
        transition: all 0.15s linear;
      }
    }
  }
  [class$='-menu'] {
    background-color: #fff;
    border: 1px solid #b3b3b3;
    border-radius: 0;
    color: #00933c;

    > * {
      padding: 0;
      text-align: center;
      cursor: pointer;
      min-height: 52px;
      &:hover,
      &:active,
      &:focus-within {
        background-color: red;
      }
    }
  }
  .menu-closed,
  .menu-open {
    &:before {
      content: '';
      background-image: url(../images/icon-arrow-green.svg);
      background-size: 15px 15px;
      background-repeat: no-repeat;
      width: 15px;
      height: 15px;
      position: absolute;
      right: calc(50% - 50px);
      top: calc(50% - 7px);
      z-index: 2;
      pointer-events: none;
    }
  }
  .menu-closed {
    &:before {
      transform: rotate(0);
      transition: all 0.15s linear;
    }
  }
  .menu-open {
    &:before {
      transform: rotate(180deg);
      transition: all 0.15s linear;
    }
  }
  [class$='-ValueContainer'] {
    padding: 0;
  }
  input {
    max-height: 56px;
    caret-color: rgba(0, 0, 0, 0);
  }
  [class$='-MenuList'] {
    [class$='-option'] {
      background-color: #fff;
      color: #000;
      &:hover {
        background-color: #00933b;
        color: #fff;
      }
    }
    > * {
      min-height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:active {
        background: rgb(10, 112, 39);
        color: #000;
        cursor: pointer;
      }
      &:last-child {
        border-top: 1px solid #fff;
      }
    }
  }
}

.video-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 1rem;
  background: #000;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  @media screen and (min-width: 576px) {
    padding: 0 4rem;
  }
  @media screen and (min-width: 1200px) {
    padding: 0 12rem;
  }
  .video-js {
    border: 4px solid white;
    top: 50%;
    transform: translateY(-50%);
    max-width: 2070px !important;
    margin: 0 auto;
  }
  .video-js.vjs-fullscreen {
    border: none;
  }
  .vjs-big-play-button {
    background-color: #00933c !important;
    background-image: url('../images/icon-arrow-play.svg');
    background-position: 50% 50%;
    background-size: 40px;
    background-repeat: no-repeat;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 576px) {
      background-size: 65px;
    }
    @media screen and (min-width: 992px) {
      background-size: 86px;
    }
    &:before {
      content: '';
      display: none;
    }
    &:hover {
      background-color: transparent;
      opacity: 0.7;
    }
  }
  .video-js .vjs-big-play-button {
    height: 2em;
    width: 2em;
    margin-top: -1em;
    margin-left: -1em;
    border-radius: 50%;
    @media screen and (min-width: 576px) {
      height: 4em;
      width: 4em;
      margin-top: -2em;
      margin-left: -2em;
    }
    @media screen and (min-width: 992px) {
      height: 5em;
      width: 5em;
      margin-top: -2.5em;
      margin-left: -2.5em;
    }
  }
  .video-js .vjs-control-bar,
  .video-js .vjs-big-play-button,
  .video-js .vjs-modal-dialog {
    background-color: #00933c;
  }
  button {
    border: none !important;
  }
  .vjs-big-play-button .vjs-icon-placeholder:before {
    display: none;
  }
}

.sign-up {
  .signin-form {
    display: none;
    @media screen and (min-width: 992px) {
      display: inline;
    }
  }
}

.vjs-big-play-button {
  display: none !important;
}

.landing-embed-form {
  .chimney-embed {
    background-color: transparent;
    padding-top: 0;
    margin-top: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .chimney-form-input {
      border: none;
      background-color: $white;
      border-radius: 0.5rem;
      color: black;
      font-family: 'PlantinStd-Light';
      font-weight: normal;
      text-transform: none;
      font-size: 18px;

      @media screen and (min-width: 576px) {
        font-size: 20px;
      }

      &::placeholder {
        color: #777;
        font-size: 20px;
        font-weight: 100;
        font-family: 'BebasNeue';
        text-transform: uppercase;

        @media screen and (min-width: 576px) {
          font-size: 22px;
        }
      }

      &:focus {
        border: none;
      }
    }

    .chimney-step-intro {
      display: none;
    }

    .chimney-input-container {
      &.type-submit {
        text-align: center;
      }

      &.type-checkbox {
        padding-bottom: 5px;

        .chimney-checkbox-label {
          margin-left: 50px;
          font-family: 'PlantinStd-Light';
          font-size: 16px;

          @media screen and (min-width: 400px) {
            margin-bottom: 5px;
          }
        }

        .chimney-form-checkmark {
          border-radius: 0.5rem;
          background-color: #fff;
          padding: 1rem;

          &:after {
            left: 11px;
            top: 5px;
          }
        }
      }

      .chimney-input-inner {
        input,
        textarea {
          color: black;
        }
      }

      &.invalid .chimney-input-inner {
        input,
        textarea,
        select {
          border-width: 1px;
        }

        &:before,
        &:after {
          display: none;
        }
      }

      .chimney-validation-flag {
        margin-top: 2px;

        .chimney-validation-flag-title {
          background: transparent;
          color: red;
          font-weight: normal;
        }
      }
    }

    .chimney-btn {
      border-radius: 0.5rem;
      min-height: 64px;
      margin-left: auto;
      margin-right: auto;
      letter-spacing: 0.026rem;
      font-weight: normal;

      &.chimney-loading {
        text-indent: inherit;
      }
    }

    .chimney-sticky-container.sticky .chimney-entry-button {
      background-color: transparent;
      text-align: center;

      &:before,
      &:after {
        display: none;
      }
    }

    .chimney-complete-message {
      font-family: 'BebasNeue';
      font-weight: normal;

      p {
        font-size: 26px;
        line-height: 1.2;

        @media screen and (min-width: 768px) {
          font-size: 32px;
        }

        @media screen and (min-width: 992px) {
          font-size: 36px;
        }
      }
    }

    // TODO: keeping in case we will need it at later point - it's a quick fix for undefined localization string in Chimney
    .chimney-dropzone-area .chimney-btn {
      // font-size: 0;
      // position: relative;

      font-size: 16px;
      @media screen and (min-width: 768px) {
        font-size: 18px;
      }

      // &:after {
      //   content: "Select a photo or video";
      //   font-size: 16px;
      //   background: black;
      //   position: relative;
      //   left: -5px;

      //   @media screen and (min-width: 768px) {
      //     font-size: 18px;
      //   }
      // }
    }

    .chimney-progress-bar .chimney-progress-line {
      background-color: #77ffc0;
    }

    .chimney-upload-skip {
      .chimney-btn {
        font-size: 18px;
        min-height: 40px;
      }
    }

    .chimney-back-to-user-details,
    .chimney-back-to-upload {
      box-shadow: none;
      margin-bottom: 0;
      padding: 0;
      min-height: 30px;

      @media screen and (min-width: 768px) {
        min-height: 40px;
      }

      .chimney-icon {
        position: relative;
        top: -1px;

        @media screen and (min-width: 768px) {
          top: -2px;
        }
      }
    }
  }
}

.homepage-video-container {
  max-width: 1000px;
  margin: 0 auto 20px;

  .vjs-poster {
    background-size: 101%;
  }
}

.iframe-video-container {
  position: relative;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.comments-header {
  font-size: 26px;
  line-height: 1.2;

  @media screen and (min-width: 768px) {
    font-size: 2rem;
  }

  @media screen and (min-width: 992px) {
    font-size: 2.375rem;
  }
}

.comment {
  text-align: left;
  padding-top: 1.25rem;
  padding-bottom: 1.125rem;

  border-bottom: 1px solid #4a584e;

  @media screen and (min-width: 768px) {
    padding-top: 2rem;
    padding-bottom: 1.75rem;
  }

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }

  .subheader-text {
    text-align: left;
    margin: 0;
    font-size: 20px;
    line-height: 148%;

    @media screen and (min-width: 768px) {
      font-size: 20px;
      line-height: 148%;
    }
  }

  .author-date {
    padding-top: 0.5rem;

    .author {
      margin-right: 0.5rem;
      font-size: 20px;
    }

    .date {
      color: #669077;
      font-family: 'Plantin Std';
      font-weight: 300;
      font-size: 20px;
      line-height: 2.8125rem;
      margin-right: 0.5rem;
    }
  }
}

.read-more {
  cursor: pointer;
  color: #fff;
  font-family: 'BebasNeue';
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0.04125rem;
  white-space: nowrap;
  transition: all 0.3s ease;

  &:hover {
    color: #7affcb;
  }
}

.comments-container {
  max-width: 692px;
  margin: auto;
}

.seated-events-table {
  border-top: none !important;
}

.seated-no-events {
  font-family: 'PlantinStd-Light';
  font-size: 16px !important;
  margin-bottom: 0;
  text-align: center;

  & + div {
    text-align: center !important;
    margin: 0 !important;
    font-size: 14px !important;

    a {
      font-size: 14.5px !important;
    }
  }
}

.seated-event-venue-name,
.seated-event-date-cell,
.seated-event-details-cell,
.seated-event-venue-location,
.seated-event-details-sold-out {
  font-family: 'BebasNeue' !important;
  letter-spacing: 0.025em !important;
}

.seated-event-date-cell {
  font-family: 'BebasNeue';
  font-size: 18px !important;
}

.seated-event-venue-name {
  font-size: 22px !important;
}

.seated-event-venue-location {
  font-size: 22px !important;
}

.seated-events-table > div:last-of-type {
  font-size: 14px !important;
  a {
    font-size: 15px !important;
  }
}

.seated-event-row {
  border-bottom: 1px solid #2b3d33 !important;
}

.seated-event-link,
.seated-follow-link {
  font-family: 'BebasNeue' !important;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  background-color: #000 !important;
  border: 1px solid #000 !important;
  border-radius: 0.5rem;
  padding: 8px 32px;
  box-shadow: none;
  border: none;
  transition: all 0.3s ease;
  letter-spacing: 0.025em !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);

  &:disabled {
    opacity: 0.7 !important;
    cursor: not-allowed !important;
  }

  &:hover,
  &:active,
  &:focus-visible {
    background-color: #7affcb !important;
    color: #000 !important;
    border: 1px solid #7affcb !important;
    transition: all 0.3s ease !important;
  }
}

.seated-widget-container {
  max-width: 1000px;
  margin: auto;
}

.seated-widget-description {
  text-align: center;

  p {
    font-family: 'PlantinStd-Light';
    font-size: 18px;

    @media screen and (min-width: 768px) {
      font-size: 22px;
    }
  }
}

.header-image {
  max-width: 1000px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.4);
}

a.seated-event-link {
  font-size: 18px !important;
}

.seated-follow-box {
  .seated-follow-text {
    font-family: 'PlantinStd-Light';
    font-size: 16px;
  }
  a.seated-follow-link {
    font-size: 22px !important;
  }
}

.headline-divider {
  display: flex;
  justify-content: center;
  padding: 20px 0;

  @media screen and (min-width: 768px) {
    padding: 30px 0;
  }

  span {
    width: 20px;
    height: 2px;
    background: $white;
    border-radius: 2px;
  }
}

.headline-text {
  font-family: 'PlantinStd-Light';
  font-weight: normal;
  text-align: center;
  font-size: 20px;
  line-height: 1.2;
  font-style: italic;
  margin-bottom: 10px;

  @media screen and (min-width: 768px) {
    font-size: 22px;
  }

  @media screen and (min-width: 992px) {
    font-size: 28px;
  }
}

.headline-link-container {
  display: flex;
  justify-content: flex-end;
}

.headline-link {
  color: $white;
  font-family: 'BebasNeue';
  font-weight: normal;
  text-align: center;
  font-size: 20px;
  line-height: 1.4;
  transition: all 0.3s ease;
  text-decoration: none;

  @media screen and (min-width: 768px) {
    font-size: 28px;
  }

  &:hover {
    color: #7affcb;
  }
}
